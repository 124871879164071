<template>
  <b-row>
    <b-col cols="12">
      <b-card no-body>
        <b-card-header>
          <b-card-title>Bekleyen Stoklar</b-card-title>
          <div>
            <!--            <b-button-->
            <!--              to="/stocks/batch-add"-->
            <!--              variant="primary"-->
            <!--              class="mr-2"-->
            <!--            >-->
            <!--              Toplu Stok Ekle-->
            <!--            </b-button>-->
            <b-button
              to="/stocks/allocate-add"
              variant="primary"
              class="mr-2"
            >
              Tahsis Stok Ekle
            </b-button>
            <b-button
              to="/stocks/custom-stock"
              variant="primary"
            >
              Özel Stok Ekle
            </b-button>
          </div>

        </b-card-header>
        <b-card-body>
          <v-select
            id="id_com_brand"
            v-model="id_com_brand"
            placeholder="Marka"
            :options="brands"
            label="title"
            :reduce="item => item.id"
            @input="getDataList"
          />
        </b-card-body>
        <b-table
          v-if="dataList.length > 0"
          responsive="sm"
          :fields="fields"
          :items="dataList"
          striped
          hover
        >
          <template #cell(brand)="data">
            {{ data.item.brand }} {{ data.item.model }}
            <div class="font-small-2 text-warning">
              {{ data.item.arac_cinsi }}
            </div>
            <div class="font-small-2 text-primary">
              {{ data.item.sase }} - {{ data.item.motor_no }}
            </div>
            <div class="font-small-2 text-muted">
              Fatura: {{ moment(data.item.FATURA_TARIHI).format('DD.MM.YYYY') }} - {{ data.item.fatura_no }}
            </div>
          </template>
          <template #cell(control)="data">
            <b-button
              variant="danger"
              size="sm"
              :to="'/stocks/add/' + data.item.sase"
            >
              Stok Listesine Ekle
            </b-button>
          </template>
        </b-table>
        <b-card-body v-else>
          <b-alert show>
            <div class="alert-body text-center">
              Bekleyen stok bulunamadı.
            </div>
          </b-alert>
        </b-card-body>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BButton,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BAlert,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'Index',
  components: {
    BCard,
    BTable,
    BRow,
    BCol,
    BButton,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BAlert,
    vSelect,
  },
  data() {
    return {
      id_com_brand: null,
      userData: JSON.parse(localStorage.getItem('userData')),
      currentPage: 1,
      perPage: 10,
      fields: [
        {
          key: 'brand',
          label: 'Marka',
        },
        {
          key: 'sase',
          label: 'ŞASE NO',
        },
        {
          key: 'control',
          label: '',
          tdClass: 'width-auto text-right',
        },
      ],
      dataQuery: {
        select: [
          'com_brand.name AS brand',
          '_mssql_aracstok.MODEL AS model',
          '_mssql_aracstok.ARAC_CINSI AS arac_cinsi',
          '_mssql_aracstok.SASE AS sase',
          '_mssql_aracstok.MOTOR_NO AS motor_no',
          '_mssql_aracstok.MODEL_YILI AS model_yili',
          '_mssql_aracstok.FATURA_TARIHI AS fatura_tarihi',
          '_mssql_aracstok.FATURA_NO AS fatura_no',
          '_mssql_aracstok.ALIM_TUTARI AS alim_tutari',
        ],
        where: {
          'com_stock.id': null,
        },
      },
    }
  },
  computed: {
    dataList() {
      return this.$store.getters['stock/getLogoStock']
    },
    brands() {
      return this.$store.getters['brands/getBrands']
    },
  },
  watch: {
    currentPage(val) {
      const page = (val - 1) * 10
      this.pagination(page)
    },
  },
  created() {
    this.getDataList()
    this.getBrands()
  },
  methods: {
    getBrands() {
      this.$store.dispatch('brands/brandsList', {
        select: ['com_brand.id AS id', 'com_brand.name AS title'],
        where: {
          'com_brand.salestatus': 1,
        },
      })
    },
    getDataList() {
      if (!this.dataQuery.where['_mssql_aracstok.ID_COM_BRAND']) {
        if (['1', '2', '3', '4', '5', '6', '13'].includes(this.userData.id_com_brand)) {
          this.dataQuery.where['_mssql_aracstok.ID_COM_BRAND'] = this.userData.id_com_brand
        }
      }
      if (this.id_com_brand) {
        this.dataQuery.where['_mssql_aracstok.ID_COM_BRAND'] = this.id_com_brand
      }
      this.$store.dispatch('stock/logoStock', this.dataQuery)
    },
  },
}
</script>
